<template>
  <div class="planning-create">
    <div class="date-select">
      <router-link
        v-if="mode === 'info'"
        class="back-button"
        to="/planning"
      ></router-link>
      <div
        v-if="mode === 'select'"
        class="date-select__header date-select__header--same-width"
      >
        <div class="date-select__header-item">Год</div>
        <div class="date-select__header-item">Месяц</div>
      </div>
      <div v-else class="date-select__header">
        <div class="date-select__header-item">
          {{ getDate(selectedDate).month }}
        </div>
        <div class="date-select__header-item">
          {{ getDate(selectedDate).year }}
        </div>
      </div>
      <div class="date-select__body">
        <template v-if="mode === 'select'">
          <div
            class="date-select__body-item date-select__body-item--same-width"
            v-for="date of dates"
            :key="date.id"
            @click="setDate(date)"
          >
            <div class="date-select__body-item-col">
              {{ getDate(date.label).year }}
            </div>
            <div class="date-select__body-item-col">
              {{ getDate(date.label).month }}
            </div>
          </div>
        </template>
        <template v-else-if="monthInfo !== null">
          <div class="date-select__body-item">
            <div class="date-select__body-item-col">Кол-во рабочих дней:</div>
            <div class="date-select__body-item-col">
              {{ monthInfo.working_days }}
            </div>
          </div>
          <div class="date-select__body-item">
            <div class="date-select__body-item-col">
              Кол-во сокращенных дней:
            </div>
            <div class="date-select__body-item-col">
              {{ monthInfo.shortened_days }}
            </div>
          </div>
          <div class="date-select__body-item">
            <div class="date-select__body-item-col">
              Кол-во рабочих часов (8):
            </div>
            <div class="date-select__body-item-col">
              {{ monthInfo.working_8_hours }}
            </div>
          </div>
          <div class="date-select__body-item">
            <div class="date-select__body-item-col">
              Кол-во рабочих часов (6):
            </div>
            <div class="date-select__body-item-col">
              {{ monthInfo.working_6_hours }}
            </div>
          </div>
        </template>
        <template v-else>
          <div class="date-select__body-item date-select__body-item--no-data">
            Нет данных
          </div>
        </template>
      </div>
    </div>
    <Pagination v-if="mode === 'select'" :data="datesData" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { MONTHS } from "@/assets/constants.js";
import Pagination from "@/components/Pagination.vue";
import dayjs from "dayjs";

export default {
  name: "date-setter",
  components: {
    Pagination,
  },
  props: {
    mode: {
      type: String,
      require: true,
      default: "select",
      validator: (value) => ["select", "info"].includes(value),
    },
    monthInfo: {
      type: Object,
      default: function () {
        return {};
      },
    },
    selectedDate: {
      type: String,
    },
    dates: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    ...mapState({
      datesData: (state) => state.planningsStore.datesData,
    }),
  },
  methods: {
    getDate(value) {
      const date = dayjs(value);
      const year = date.format("YYYY");
      const monthIdx = date.month();
      return { year, month: MONTHS[monthIdx] };
    },
    setDate(date) {
      this.$emit("setDate", date);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/css/variables.scss";

.planning-create {
  width: min-content;
}

.date-select {
  width: 350px;
  border: 1px solid $erp-primary-bg-color;
  border-radius: 5px;
  cursor: default;
  box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
  margin-bottom: 40px;
  position: relative;
  user-select: none;
}

.date-select__header {
  display: grid;
  min-height: 25px;
  height: auto;
  grid-template-columns: 80% auto;
  border-bottom: 1px solid $erp-primary-bg-color;
  background-color: $erp-primary-bg-color;
  color: #ffffff;
  & .date-select__header-item:first-child {
    padding-left: 20px;
  }
  & .date-select__header-item:last-child {
    justify-self: center;
  }
}

.date-select__header--same-width {
  grid-template-columns: repeat(2, 1fr);
  & .date-select__header-item {
    justify-self: center;
  }
}

.date-select__header-item {
  align-self: center;
}

.date-select__body {
  height: auto;
  overflow-y: auto;
}

.date-select__body::-webkit-scrollbar {
  width: 3px;
}

.date-select__body::-webkit-scrollbar-thumb {
  background-color: #9fafb9;
  border-radius: 10em;
}

.date-select__body-item {
  display: grid;
  grid-template-columns: 80% auto;
  transition: 300ms background-color;
  height: auto;
  min-height: 25px;
  & .date-select__body-item-col:first-child {
    padding-left: 20px;
  }
  & .date-select__body-item-col:last-child {
    justify-self: center;
  }
}

.date-select__body-item--same-width {
  grid-template-columns: repeat(2, 1fr);
  & .date-select__body-item-col {
    justify-self: center;
  }
}

.date-select__body-item:not(:last-child) {
  border-bottom: 1px solid #b7c7cf;
}

.date-select__body-item--selected {
  background-color: #99baca;
}

.date-select__body-item--same-width:hover {
  background-color: #b7c7cf;
  cursor: pointer;
}

.date-select__body-item--no-data {
  font-size: 28px;
  padding: 12px;
  grid-template-columns: 100%;
  text-align: center;
}

.date-select__body-item-col {
  align-self: center;
}

.planning-create__button {
  display: block;
  padding: 7px 14px;
  border: 1px solid $erp-primary-bg-color;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 auto;
  transition: 300ms background-color;
}

.planning-create__button:hover {
  background-color: #26323817;
  box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
}

.back-button {
  position: absolute;
  background-color: #99baca;
  left: -15px;
  bottom: 50%;
  height: 50%;
  width: 14px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  opacity: 0.15;
  transition: 300ms opacity;
}

.back-button::before {
  content: "";
  width: 0;
  height: 0;
  display: block;
  position: absolute;
  z-index: 10;
  border: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  margin-top: -8px;
  top: 50%;
  border-right: 6px solid #ffffff;
  left: 4px;
}

.back-button:hover {
  opacity: 0.8;
}
</style>
